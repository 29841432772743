import React from 'react';
import { RichTextInput, RichTextInputToolbar, LinkButtons, FormatButtons } from 'ra-input-rich-text';


const MyToolBar = () => {
  return (
      <RichTextInputToolbar>
        <FormatButtons />
        <LinkButtons />
      </RichTextInputToolbar>
    )
};

const PostTextInput = ({source, ...props}: any) => {
  return (
    <RichTextInput
      toolbar={<MyToolBar />}
      label="Содержание"
      source={source}
      {...props}
    />
  )
}

export default PostTextInput;