import React from "react";
import {
    List,
    Datagrid,
    TextField,
    Show,
    TabbedShowLayout,
    FunctionField, FileField,
    Create, SimpleForm, TextInput, DateTimeInput, Edit, ShowButton, EditButton, FileInput, DateField, RichTextField
} from "react-admin";
import SendManagePanel from "./components/SendManagePanel/SendManagePanel";
import ListSendButton from "./components/SendManagePanel/ListSendButton";
import { SendStatus } from "./interface";
import PostFile from "./components/PostFile/PostFile";
import { CreateFormToolBar, UpdateFormToolBar } from "./components/FormToolBar/FormToolBar";
import PostTextInput from "./components/PostTextInput/PostTextInput";

export const PostList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField label="Заголовок" source="title" />
            <DateField label="Дата и время публикации" source="schedule_datetime" showTime locales='ru-RU' />
            <FunctionField
                label="Статус отправки"
                source="static"
                render={(record: any) => (
                    <>
                        {record.static && <a href={record.static}>Медиа файл</a>}
                        {!record.static && <span>Без файла</span>}
                    </>
                )}
            />
            <ShowButton />
            <EditButton />
            <FunctionField
                label="Статус отправки"
                source="send_status"
                render={(record: any) => (
                    SendStatus[record.send_status].title
                )}
            />
            <ListSendButton />
        </Datagrid>
    </List>
)

export const PostDetail = () => {

    return (
        <Show>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Содержание поста">
                    <TextField label="Заголовок" source="title" />
                    <RichTextField label="Содержание" source="text" fullWidth/>
                    <PostFile />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Панель управления">
                    <FunctionField label="Статус отправки" source="send_status"
                            render={(record: any) => (
                                SendStatus[record.send_status].title
                            )}
                        />
                    <DateField label='Дата и время публикации' source="schedule_datetime" showTime locales='ru-RU'/>
                    <SendManagePanel />
                </TabbedShowLayout.Tab>
            </TabbedShowLayout> 
        </Show>
    )
}

export const PostCreate = () => (
    <Create>
        <SimpleForm toolbar={<CreateFormToolBar />}>
            <TextInput type="text" label="Заголовок" source="title" fullWidth resettable/>
            <aside>
                Если содержание поста превышает 4096 символов, то пост будет разбит на несколько сообщений.
            </aside>
            <aside>
                Телегамм поддерживает определенные html-теги для стилизации текста.
                <a href="https://core.telegram.org/bots/api#html-style" target='_blank' rel="noreferrer">
                    Подробнее
                </a>
            </aside>
            <PostTextInput source="text"/>
            <DateTimeInput label='Дата и время публикации' source="schedule_datetime" />
            <aside>
                {
                    "Размер фотографии не должен превышать 10 MB.\
                    Сумма ширины и высоты фотографии не должны превышать 10000 пикселей.\
                    Соотношение высоты и ширины фото не должно превышать 20."
                }
            </aside>
            <FileInput label='Файл' source="static" maxSize={20000000}>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Create>
)

export const PostUpdate = () => (
    <Edit>
        <SimpleForm
            toolbar={
                <UpdateFormToolBar />
            }
        >
            <TextInput type="text" label="Заголовок" source="title" fullWidth resettable/>
            <aside>
                Если содержание поста превышает 4096 символов, то пост будет разбит на несколько сообщений.
            </aside>
            <aside>
                Телегамм поддерживает определенные html-теги для стилизации текста.
                <a href="https://core.telegram.org/bots/api#html-style" target='_blank' rel="noreferrer">
                    Подробнее
                </a>
            </aside>
            <PostTextInput source="text"/>
            <DateTimeInput source="schedule_datetime" />
            <aside>
                {
                    "Размер фотографии не должен превышать 10 MB.\
                    Сумма ширины и высоты фотографии не должны превышать 10000 пикселей.\
                    Соотношение высоты и ширины фото не должно превышать 20."
                }
            </aside>
            <FileInput label='Файл' source="static" maxSize={20000000}>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Edit>
)

