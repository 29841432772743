import React from 'react';
import { Toolbar, SaveButton, useNotify, useRedirect, UpdateParams } from 'react-admin';
import dataProvider from '../../../../api/dataProvider';


const updatePost = async (data:any) => {
    const resource = 'posts';
    return await dataProvider.update(resource, ({data: data} as UpdateParams<any>))
}

// WORKING ONLY FOR CREATE
export const CreateFormToolBar = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    return (
        <Toolbar>
            <SaveButton
                type="button"
                label="Сохранить"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Запись сохранена');
                        redirect('/posts');
                    }
                }}
            />
            <SaveButton
                variant="text"
                type="button"
                label="Сохранить и отправить"
                mutationOptions={{
                    onSuccess: (responseData) => {
                        dataProvider.sendPost(responseData.id);
                        notify('Запись сохранена и поставлена на отправку!');
                        redirect('/posts');
                    }
                }}
            />
        </Toolbar>
    )
}

// WORKING ONLY FOR UPDATE
export const UpdateFormToolBar = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    return (
        <Toolbar>
            <SaveButton
                label="Сохранить"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Запись сохранена');
                        redirect('/posts');
                    }
                }}
            />
            <SaveButton
                variant="text"
                type="button"
                label="Сохранить и отправить"
                mutationOptions={{
                    onSuccess: (data) => {
                        updatePost(data).then(response => dataProvider.sendPost(data.id));
                        notify('Запись сохранена и поставлена на отправку!');
                        redirect('/posts');
                    }
                }}
            />
        </Toolbar>
    )
}
