import React from "react";
import { useRecordContext, useRefresh } from "react-admin";
import dataProvider from "../../../../api/dataProvider";
import './SendManagePanel.scss';
import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { SendStatus } from "../../interface";


export const SendPostButton = ({record_id, isDisable, setDisable, refresh}: any) => {
    return (
        <button
            className="ListSendButton__button"
            onClick={() => {
                setDisable(true);
                dataProvider.sendPost(record_id);
                setTimeout(() => {
                    setDisable(false);
                }, 10000)
                refresh();
            }}
            disabled={isDisable}
        >
            <SendIcon />
            <span className="ListSendButton__button-text">
                {isDisable && 'Отправляется'}
                {!isDisable && 'Поставить на отправку'}
            </span>
        </button>
    )
}

export const CancelSendButton = ({record_id, isDisable, setDisable, refresh}: any) => {

    return (
        <button
            className="ListSendButton__button"
            onClick={() => {
                setDisable(true);
                dataProvider.cancelSendPost(record_id)
                setTimeout(() => {
                    setDisable(false);
                }, 10000)
                refresh();
            }}
            disabled={isDisable}
        >
            <CancelScheduleSendIcon />
            <span className="ListSendButton__button-text">
                {isDisable && 'Отправляется'}
                {!isDisable && 'Отменить на отправку'}
            </span>
        </button>
    )
}

const ListSendButton = () => {
    const record = useRecordContext();
    const [isDisable, setDisable] = React.useState<boolean>(false);
    const refresh = useRefresh();

    return (
        <>
            {!SendStatus[record.send_status].isOnWork && <SendPostButton record_id={record.id} refresh={refresh} setDisable={setDisable} isDisable={isDisable} /> }
            { SendStatus[record.send_status].isOnWork && <CancelSendButton record_id={record.id} refresh={refresh}  setDisable={setDisable} isDisable={isDisable} /> }
        </>
    )
}


export default ListSendButton;