import React from "react";
import dataProvider from "../../../../api/dataProvider";
import './Place.scss';


const PlaceCard = ({placeData}: any) => {
    return (
        <div className="Place">
            <div>
                <h4 className="Place-title">{placeData.title}</h4>
                <p className="gold">{placeData.category}</p>
            </div>
            <img className="Place__img" src={placeData.static[0]} alt=''/>
        </div>
    )
}



const Place = ({ place }: any) => {
    const [placeData, setPlaceData] = React.useState<any>();
    const [loadMessage, setLoadMessage] = React.useState<string>('Загрузка');

    React.useEffect(() => {
        dataProvider.getPlaceDetail(place.place_id, place.place_city)
            .then((response: any) => {
                setPlaceData(response.data.json);
            }).catch((error: any) => {
                setLoadMessage('Ошибка отображения');
            })
    }, [])

    return (
        <>
            {placeData && <PlaceCard placeData={placeData} />}
            {!placeData && <div>{loadMessage}</div>}
        </>
    )
}

export default Place;