import { CreateParams, DataProvider } from "react-admin";
import simpleRestProvider from 'ra-data-simple-rest';
import { dataProviderClient } from "./client";


const BaseDataProvider = simpleRestProvider("/api/admin", dataProviderClient, 'X-Total-Count');

const dataProvider = {
    ...BaseDataProvider,
    create: async (resource: string, params: CreateParams<object> | any) => {
        switch (resource) {
            case 'posts':
                const response = await dataProvider.createPost(params.data)
                return {data: response};
        }        
    },
    update: async (resource, params) => {
        switch (resource) {
            case 'posts':
                const responseData = await dataProvider.updatePost(params.data)
                return {data: responseData};
        }        
    },
    createPost: async (data: object | any) => {
        const resource = 'posts';
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('text', data.text);
        if (data.schedule_datetime) {
            formData.append('schedule_datetime', data.schedule_datetime.toISOString());
        }
        if (data.static) {
            formData.append('file', data.static.rawFile, data.static.title);
        }
        const reponse = await dataProviderClient(
                            `api/admin/${resource}`,
                            {
                                method: "POST",
                                body: formData,
                            }
                        );
        return reponse.json;
    },
    updatePost: async (data: object | any) => {
        const resource = 'posts';
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('text', data.text);
        if (data.schedule_datetime) {
            formData.append('schedule_datetime', new Date(data.schedule_datetime).toISOString());
        }
        if (data.static) {
            formData.append('file', data.static.rawFile, data.static.title);
        }
        const response = await dataProviderClient(
            `api/admin/${resource}/${data.id}`,
            {
                method: "POST",
                body: formData,
            }
        )
        return response.json;
    },
    sendPost: (post_id: number) => {
        const resource = 'posts';
        dataProviderClient(
            `api/admin/${resource}/${post_id}/send`,
            {
                method: "POST",
            }
        );
    },
    cancelSendPost: (post_id: number) => {
        const resource = 'posts';
        dataProviderClient(
            `api/admin/${resource}/${post_id}/send/cancel`,
            {
                method: "POST",
            }
        );
    },
    getPlaceDetail: async (place_id: number, city_id: number) => {
        const response = await dataProviderClient(
            `api/webapp/places/place?id=${place_id}&city=${city_id}`,
            {
                method: "GET",
            }
        );
        return {data: response};
    }
} as DataProvider;

export default dataProvider;