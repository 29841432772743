import React from "react";
import { useRecordContext, useRefresh } from "react-admin";
import './SendManagePanel.scss';
import { SendPostButton, CancelSendButton } from "./ListSendButton";


const SendManagePanel = () => {
    const record = useRecordContext();
    const [isDisable, setDisable] = React.useState<boolean>(false);
    const refresh = useRefresh();

    return (
        <div className="sendManagePanel">
            {isDisable ?
                <span className="ListSendButton__button-text">Отправляется</span>
                :
            <div className="sendManagePanel__panel">
                <SendPostButton record_id={record.id} refresh={refresh} setDisable={setDisable} isDisable={isDisable} />
                <CancelSendButton record_id={record.id} refresh={refresh} setDisable={setDisable} isDisable={isDisable} />
            </div>
            }

        </div>
    )
}


export default SendManagePanel;