import React from "react";
import { useRecordContext } from "react-admin";
import './PostFile.scss';


const PostFileCard = ({fileSource}: any) => {
    return (
        <div className="PostFile">
            <a href={`/${fileSource}`}>{fileSource}</a>
            <img className="PostFile__image" src={`/${fileSource}`} alt='' />
        </div>
    )
}

const PostFile = () => {
    const record = useRecordContext();
    return (
        <>
            {record.static && <PostFileCard fileSource={record.static} />}
            { !record.static && <PostFileCard fileSource={record.static} />}
        </>

    )
}

export default PostFile;