interface ISendStatus {
    [index: string]: ISendStatusInfo;
}
interface ISendStatusInfo{
    title: string;
    description: string;
    isOnWork: boolean;
}

export const SendStatus: ISendStatus = {
    'PENDING': {
        title: "Ожидает выполнения",
        description: "Ождиает время наступления даты отправления",
        isOnWork: true
    },
    'FAILURE': {
        title: "Неудача",
        description: "Ошибка во время выполнения",
        isOnWork: false
    },
    'RECEIVED': {
        title: "Поставлено на отправку",
        description: "",
        isOnWork: true
    },
    'RETRY': {
        title: "Повторная попытка выполнения",
        description: "Ожидает повторной попытки выполнения",
        isOnWork: false
    },
    'STARTED': {
        title: "В процессе отправки",
        description: "",
        isOnWork: true
    },
    'SUCCESS': {
        title: "Успешно отправлено",
        description: "",
        isOnWork: false
    },
    "NOT SET TO SEND": {
        title: "Не поставлено на отправку",
        description: "Нажмите кнопку поставить на отправку, если хотите отправить сообщение.",
        isOnWork: false
    }
}