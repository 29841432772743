import Cookies from 'js-cookie';
import { dataProviderClient } from "./client";


interface AuthProps {
    login: string;
    password: string;
}

export const authProvider = {
    login: async ({ login, password }: AuthProps) => {
        const response = await dataProviderClient(
            '/api/auth',
            {
                method: "POST",
                body: JSON.stringify({login: login, password: password})
            }
        );
        return Promise.resolve();
    },
    logout: async () => {
        Cookies.remove('session')
        return Promise.resolve();
    },
    checkAuth: async (error: any) => {
        const response = await dataProviderClient(
            '/api/auth/check',
        );
        if (response.status === 204) {
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    },
    checkError: (error: any) => {
        if (error.status === 401) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getIdentity: () => {
        const constData = {
            id: 0,
            fullName: "GreatList Admin",
            avatar: undefined,
        }
        return Promise.resolve(constData);
    },
    getPermissions: () => {return Promise.resolve()},
}
