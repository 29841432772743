import React from 'react';
import './App.css';
import './style/main.scss';
import { Admin, Resource, defaultTheme } from "react-admin";
import dataProvider from './api/dataProvider';
import { UserList, UserDetail } from './pages/User/User';
import { PostCreate, PostDetail, PostList, PostUpdate } from './pages/Post/Post';
import { authProvider } from './api/authProvider';
import LoginPage from './pages/Login/Login';
import { Dashboard } from './pages/Dashboard/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ForumIcon from '@mui/icons-material/Forum';


function App() {
  const theme: object = {
    ...defaultTheme,
    palette: {
      mode: 'dark',
    },
    spacing: 8,
  };

  return (
    <Admin
      theme={theme}
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={LoginPage}
      dashboard={Dashboard}
    >
      <Resource
        icon={GroupIcon}
        options={{ label: "Пользователи" }}
        name="users"
        list={UserList}
        show={UserDetail}
      />
      <Resource
        icon={ForumIcon}
        options={{ label: "Посты" }}
        name="posts"
        list={PostList}
        show={PostDetail}
        create={PostCreate}
        edit={PostUpdate}
      />
    </Admin>
  );
}

export default App;
