import React from "react";
import { useRecordContext } from "react-admin";
import Place from "./Place";


const PlaceList = () => {
    const record = useRecordContext();
    return (
        <ul>
            {record.wishlist && record.wishlist.length > 0 ?
                record.wishlist.map((el: any) => (
                    <Place place={el}/>
                ))
                :
                <h2>У пользователя нет заведений в вишлисте</h2>
            }
        </ul>
    )
}

export default PlaceList;