import React from 'react';
import { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import './Login.scss';


const LoginPage = () => {
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const loginReq = useLogin();
    const notify = useNotify();

    const handleSubmit =(e: any) => {
        e.preventDefault();
        loginReq({ login, password }).catch(() =>
            notify('Invalid email or password')
        );
    };

    return (
        <div className="Login">
            <div className="container">
                <div className="LoginPage">
                    <h1 className='title-h1 gold'>Вход в GreatList Admin</h1>
                    <form className="LoginPage__form" onSubmit={handleSubmit}>
                        <input
                            placeholder='Логин'
                            name="login"
                            type="login"
                            value={login}
                            onChange={e => setLogin(e.target.value)}
                            className='LoginPage__form-input'
                        />
                        <input
                            placeholder='Пароль'
                            name="password"
                            type='password'
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            className='LoginPage__form-input'
                        />
                        <button type="submit" className="LoginPage__form-button">
                            <div className="">Вход</div>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default LoginPage;