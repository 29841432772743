import {
    List,
    Datagrid,
    TextField,
    Show,
    ShowButton,
    TabbedShowLayout
} from "react-admin";
import PlaceList from "./components/Places/PlacesList";



export const UserList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField label="Имя" source="first_name" />
            <TextField label="Фамилия" source="last_name" />
            <TextField source="username" />
            <ShowButton />
        </Datagrid>
    </List>
)

export const UserDetail = () => (
    <Show>
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label="Основная информация">
                <TextField source="id" />
                <TextField label="Имя" source="first_name" />
                <TextField label="Фамилия" source="last_name" />
                <TextField source="username" />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Вишлист">
                <h1>Вишлист</h1>
                <PlaceList />
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    </Show>
)